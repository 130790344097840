<template>
  <div class="Content">
    <div class="title">
      <span class="text">{{ title }}</span>
      <div class="line" />
    </div>

    <div v-if="contentData.length" class="content">
      <!-- 1文章、2视频、3公告、4三务公开、5精准扶贫  145一类 -->
      <div v-if="[1, 4, 5].includes(type * 1)">
        <div v-for="item in contentData" :key="item.id" class="content-item click" @click="goDetail(item.id)">
          <img v-if="item.thumbnail" :src="$beforeUrl(item.thumbnail)">
          <div class="content-text">
            <div class="contentTitle oneLine">{{ item.title }}</div>
            <div class="rich" style="max-height:52px" v-html="$setContent(item.context)" />
            <div>{{ item.createTime }}</div>
          </div>
        </div>
      </div>
      <div v-else-if="type == 2" class="type2">
        <div v-for="item in contentData" :key="item.id" class="item" @click="goDetail(item.id)">
          <div class="imgOut">
            <img class="videoImg" :src="$beforeUrl(item.videoPoster)">
            <img
              class="videoIcon"
              :src="require('@/assets/home/first/1/12.png')"
            >
          </div>
          <div class="text twoLine">{{ item.title }}</div>
        </div>
      </div>
      <div v-else-if="type == 3" class="type3">
        <div v-for="item in contentData" :key="item.id" class="content-item2">
          <div class="nameOut">
            <div class="name oneLine">{{ item.name }}</div>
            <div v-if="item.url" class="rightButton center" @click="$go(item.url)">查看官网></div>
          </div>
          <div class="type3Bottom">
            <div class="leftItem">
              <div class="tel oneLine">电话：{{ item.tel }}</div>
              <div class="address oneLine">地址：{{ item.address }}</div>
            </div>
            <div class="rightButton center" @click="goMap(item)">地图导航></div>
          </div>
        </div>
      </div>
      <div class="center">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="limit"
          class="pagination"
          :current-page="page*1"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div
      v-else
      style="flex: 1; font-size: 100px; height: 500px"
      class="colCenter it"
    >
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentData: {
      type: Array,
      default: () => [

      ]
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      page: 1, // 当前页数
      title: ''
    }
  },
  computed: {
    type() {
      return this.$parent.param.type
    },
    limit() {
      const type = this.$parent.param.type
      return [1, 4, 5].includes(type * 1) ? 10 : type == 2 ? 18 : 9
    }
  },
  mounted() {

  },
  methods: {
    handleCurrentChange(val) {
      this.$changeParam({ page: val })
    },
    goDetail(id) {
      this.$router.push({ path: '/main/detail', query: { id }})
    },
    goMap(item) {
      const { latitude, longitude, name, tel, address } = item
      this.$router.push({ path: '/map', query: { latitude, longitude, name, tel, address }})
    }
  }
}
</script>

<style lang="scss" scoped>
.Content {
  width: 100%;
  overflow: hidden;
  .title {
    border-bottom: 1px solid #e0e0e0;
    height: 41px;
    .text {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 4px solid var(--theme);
      height: 41px;
      display: inline-block;
    }
  }
  .content {

    .content-item {
      display: flex;
      height: 124px;
      border-bottom: 1px dotted #eee;
      padding-top: 20px;
      padding-bottom: 21px;
      box-sizing: content-box;

      img {
        width: 186px;
        height: 124px;
        margin-right: 16px;
      }
      .content-text {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 545px;
        div {
          display: block;
        }
        & > div:nth-child(1) {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        & > div:nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 26px;
          word-break:break-all;
        }
        & > div:nth-child(3) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
  .pagination {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .type2 {
    display: flex;
    flex-wrap:wrap;
    .item:nth-child(1){
      margin-left: 0;
    }
    .item{
      margin-left: 16.5px;
      width: 238px;
      padding-top: 20px;
      padding-bottom: 21px;
      cursor: pointer;
      .imgOut {
      position: relative;
      .videoImg {
        width: 238px;
        height: 158px;
      }
      .videoIcon {
        width: 46px;
        height: 46px;
        position: absolute;
        left: 20px;
        bottom: 20px;
      }
      }
      .text {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        flex: 1;
      }
    }
  }

  .type3{
    .content-item2{
      border-bottom: 1px dotted #eee;
      padding-top: 20px;
      padding-bottom: 21px;
      box-sizing: content-box;
        .nameOut{
        display: flex;
        .name{
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          flex:1;
        }
        .rightButton{
          width: 100px;
          height: 32px;
          background: #FFFFFF;
          border: 1px solid var(--theme);
          border-radius: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: var(--theme);
          line-height: 32px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .type3Bottom{
        display: flex;
        align-items: flex-end;
        margin-top: 16px;
        .leftItem{
          flex: 1;
          margin-right: 15px;
          overflow: hidden;
          .tel{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
          .address{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-top: 11px;
          }
        }
      }

      .rightButton{
        width: 100px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid var(--theme);
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
        line-height: 32px;
        cursor: pointer;
      }
    }
  }
}
</style>
