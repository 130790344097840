<template>
  <div class="leftItem">
    <!--<div style="font-size:18px">{{ title }}</div>-->
    <div v-for="(item,index) in leftData" :key="item.id" :class="{selected:selected===index}" @click="clickItem(index)">
      <div class="circle" />
      <div>{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leftData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: 0
    }
  },
  computed: {
    title() {
      let titles = ''
      const type = this.$parent.param.type
      if (type == 1) {
        titles = '新闻栏目'
      } else if (type == 2) {
        titles = '党建视频'
      } else if (type == 3) {
        titles = '办事服务'
      } else if (type == 4) {
        titles = '三务公开'
      } else if (type == 5) {
        titles = '精准扶贫'
      }
      return titles
    }
  },
  watch: {
    $route() {
      this.getSelected()
    },
    selected() {
      this.$parent.changeContentTitle()
    },
    leftData: {
      deep: true,
      handler() {
        this.getSelected()
      }
    }
  },

  mounted() {
  },
  created() {
    this.getSelected()
  },
  methods: {
    clickItem(index) {
      if (this.selected === index) return
      this.$changeParam({ name: '', id: this.leftData[index].id })
    },
    getSelected() {
      const { name, id } = this.$getParams()
      if (name) {
        for (const index in this.leftData) {
          if (this.leftData[index].name === name) {
            this.selected = index * 1
            return
          }
        }
      } else if (id) {
        for (const index in this.leftData) {
          if (this.leftData[index].id == id) {
            this.selected = index * 1
            return
          }
        }
      } else {
        this.selected = this.$getParams().selected * 1 || 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .leftItem{

    &>div{
      height: 60px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
       border: 1px solid #f2f2f2;
       display: flex;
       justify-content: center;
       align-items: center;
       cursor: pointer;
    }
    .circle{
      width: 3px;
      height: 3px;
      background: #666;
      border-radius: 50%;
      margin-right: 5px;
    }
    .selected{
      .circle{
        background: var(--theme);
      }
      color: var(--theme);
    }
  }
</style>
