<template>
  <div class="mainContent">
    <left ref="left" :left-data="leftData" class="left" />
    <myContent v-show="!loading" ref="content" :total="contentTotal" :param="param" :content-data="contentData" class="content" />
  </div>
</template>

<script>
import left from './components/left'
import myContent from './components/content'
export default {
  components: {
    left, myContent
  },
  props: {
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      param: {
        type: '', // 1文章、2视频、3公告、4三务公开、5精准扶贫  145一类
        title: '', // 标题
        page: '', // 第几页
        selected: '', // 选中哪一项
        id: '' // 从详情页跳回来,有这个就不要传selected了,优先级大于selected
      },

      leftData: [],

      contentData: [],
      contentTotal: 0
    }
  },
  watch: {
    async $route(val) {
      const check = this.checkIsHasLeftData(val)
      this.param = val.query || {}
      if (!check) { await this.getLeftData() } // 获取左侧边栏
      this.getInit()
    }

  },

  async created() {
    this.param = this.$getParams()
    await this.getLeftData() // 获取左侧边栏
    this.getInit()
  },
  methods: {
    getInit() {
      this.$nextTick(() => { // 中部内容
        this.$parent.loading = true
        this.getContentData()
        this.$refs.content.page = this.param.page
        this.changeContentTitle()
      })
    },
    checkIsHasLeftData(val) {
      if (!this.param.type || !this.leftData.length) return false
      if (val.query && val.query.type === this.param.type) return true
    },
    getLeftData() {
      const type = this.param.type
      this.$parent.loading = true
      return new Promise((resolve, reject) => {
        if (type == 3) {
          this.leftData = [
            { name: '办事指南', id: 1 },
            { name: '公共服务', id: 2 }
          ]
          resolve()
        } else {
          this.$store.dispatch('main/NewsCategoryGetCategoryList', { type: type || 1 }).then(res => {
            if (res.code === 0) {
              this.leftData = res.data
              resolve()
            } else {
              reject()
            }
          })
        }
      })
    },
    getContentData(page = this.param.page || 1, limit = 10, id = this.$refs.left.leftData[this.$refs.left.selected].id) {
      const type = this.param.type
      const url = [1, 4].includes(type * 1) ? 'main/NewsCategoryGetNewsData' : (type == 2 ? 'main/NewsCategoryGetVideoData' : (type == 5 ? 'main/NewsCategoryGetDiffList' : 'main/ServicesGetServiceList'))
      const data = {}
      if (type == 3) { // 办事指南,公共服务
        data.category = id // 类型
      } else if (type == 5) {
        data.categoryId = id
      }
      this.$store.dispatch(url, { page, id, limit, ...data }).then(res => {
        if (res.code === 0) {
          this.contentData = res.data
          this.contentTotal = res.count
          this.$parent.loading = false
        }
      })
    },
    changeContentTitle() {
      const left = this.$refs.left
      const leftData = left.leftData
      const selected = left.selected
      if (!leftData.length || selected >= leftData.length) return
      this.$refs.content.title = leftData[selected].name
    }

  }
}
</script>

<style lang="scss" scoped>
      .left{
      width: 196px;
    }
    .content{
      flex: 1;
      margin: 0 24px;
    }
    .mainContent{
      display: flex;
    }
</style>
